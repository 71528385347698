<template>
    <UForm :schema="schema" :state="state" @submit="onSubmit" class="grid gap-y-5">
        <div class="grid gap-4 md:grid-cols-3 md:gap-10">
            <UFormGroup label="Name" name="name">
                <UInput v-model="state.name" />
            </UFormGroup>
            <UFormGroup label="Phone number" name="phone">
                <UInput v-model="state.phone" />
            </UFormGroup>
            <UFormGroup label="Email" name="email">
                <UInput v-model="state.email" />
            </UFormGroup>
        </div>

        <div>
            <UFormGroup label="How can we help?*" name="message">
                <UTextarea v-model="state.message" />
            </UFormGroup>

            <div class="flex flex-col md:flex-row md:items-center mt-5 md:mt-3 mb-7.5 md:mb-1 gap-5 md:gap-14">
                <UCheckbox>
                    <template #label>
                        <span class="text-base font-bold">I agree to OCAUTOAID's <ULink to="/privacy-policy" class="text-blue-700">Privacy Policy.</ULink></span>
                    </template>
                </UCheckbox>

                <div class="flex items-center flex-1 gap-1.5 bg-slate-100 rounded-0.5xl p-3 text-slate-600 relative">
                    <UIIcon name="front/polygon" class="absolute h-5 w-5 -left-4 text-slate-100 justify-end hidden md:block"/>

                    <UIIcon name="front/shield-check"/>

                    <div>Your data will not be shared with third parties and will be used solely
                        for processing your request</div>
                </div>
            </div>

            <div>
                <UButton type="submit" size="xl" variant="solid" class="w-full justify-center md:w-auto">Submit</UButton>
            </div>
        </div>
    </UForm>
</template>
<script setup lang="ts">
import {z} from 'zod';
import type { FormSubmitEvent } from '#ui/types';

const schema = z.object({
    name: z.string(),
    phone: z.string(),
    email: z.string().email(),
    message: z.string()
});

type Schema = z.output<typeof schema>;

const state = reactive({
    name: undefined,
    phone: undefined,
    email: undefined,
    message: undefined
});

async function onSubmit(event: FormSubmitEvent<Schema>) {
    // Do something with data

}
</script>
